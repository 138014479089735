import {Box, IconButton, Popover, Stack, Tooltip, Typography} from "@mui/material";
import {Check, Close, ContentCopy, Delete, Edit, Link, LocationOn, PlaylistAdd} from "@mui/icons-material";

export default function ItemPopover(props) {
    const {
        anchor,
        onClose,
        type,
        item,
        beginDate,
        beginEdit,
        openCreateMenu,
        endDate,
        markComplete,
        submitDelete,
    } = props;
    
    return (
        <Popover
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={onClose}
            sx={{
                '.MuiPaper-root': {
                    borderRadius: 0,
                    border: 'solid 1px #555',
                }
            }}
        >
            {item &&
                <Box
                    sx={{
                        position: 'relative',
                        borderRadius: 0,
                        p: '10px',
                        minWidth: {
                            xs: 'calc(100% - 30px)',
                            sm: '300px',
                        },
                        background: '#222',
                        color: 'white',
                        paddingRight: `${
                            150 +
                            (type === 'Task' ? 50 : 0) +
                            (type !== 'Period' ? 50 : 0) +
                            (item.link ? 50 : 0)
                        }px`,
                    }}
                >
                    <Box
                        sx={{
                            position: {
                                xs: 'inline-block',
                                sm: 'absolute',
                            },
                            top: '5px',
                            right: '5px',
                        }}
                    >
                        {type === 'Task' &&
                            <Tooltip title={item.complete ? "Mark incomplete" : "Mark complete"}>
                                <IconButton
                                    sx={{
                                        color: '#cccccc',
                                    }}
                                    onClick={() => {
                                        markComplete(item.key, !item.complete);
                                        onClose();
                                    }}
                                >
                                    {
                                        item.complete === 1 ?
                                            <Close /> :
                                            <Check />
                                    }
                                </IconButton>
                            </Tooltip>
                        }
                        {item.link &&
                            <Tooltip title="Go to assignment">
                                <IconButton
                                    sx={{
                                        color: '#cccccc',
                                    }}
                                    onClick={() => {
                                        if (item.link.startsWith('CANVAS:')) {
                                            const lastDelimiterIndex = item.link.lastIndexOf(':');
                                            const courseId = item.link.substring(7, lastDelimiterIndex);
                                            const assignmentId = item.link.substring(lastDelimiterIndex + 1);

                                            window.open(
                                                `https://gatech.instructure.com/courses/${courseId}/assignments/${assignmentId}/`,
                                                '_blank',
                                                'noopener,noreferrer'
                                            );
                                        }

                                        onClose();
                                    }}
                                >
                                    <Link />
                                </IconButton>
                            </Tooltip>
                        }
                        {type !== 'Period' &&
                            <Tooltip title="Create period">
                                <IconButton
                                    sx={{
                                        color: '#cccccc',
                                    }}
                                    onClick={() => {
                                        openCreateMenu('Period', {
                                            name: item.name,
                                            start: item.start.getTime() < beginDate.getTime() ? beginDate : item.start,
                                            end: item.end.getTime() > endDate.getTime() ? endDate : item.end,
                                            description: item.description,
                                            location: item.location,
                                            labels: item.labels,
                                        })();
                                        onClose();
                                    }}
                                >
                                    <PlaylistAdd />
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title="Duplicate">
                            <IconButton
                                sx={{
                                    color: '#cccccc',
                                }}
                                onClick={() => {
                                    openCreateMenu(type, {
                                        name: item.name,
                                        start: item.start.getTime() < beginDate.getTime() ? beginDate : item.start,
                                        end: item.end.getTime() > endDate.getTime() ? endDate : item.end,
                                        description: item.description,
                                        location: item.location,
                                        labels: item.labels,
                                    })();
                                    onClose();
                                }}
                            >
                                <ContentCopy />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <IconButton
                                sx={{
                                    color: '#cccccc',
                                }}
                                onClick={() => {
                                    onClose();
                                    beginEdit(type, item.key)();
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton
                                sx={{
                                    color: '#cccccc',
                                }}
                                onClick={() => {
                                    onClose();
                                    submitDelete(type, item.key)();
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Typography
                        m="0 0 7px 0"
                        fontSize="14pt"
                        fontWeight="700"
                        color="#d5d5d5"
                    >
                        {item.name}
                    </Typography>
                    <Typography
                        m="0 0 10px 0"
                        fontSize="11pt"
                        fontWeight="500"
                        color="#c5c5c5"
                    >
                        {`${item.start.getHours()}`.padStart(2, '0')}:{`${item.start.getMinutes()}`.padStart(2, '0')}&nbsp;
                        {item.start.toLocaleString('default', { month: 'short', weekday: 'long', day: 'numeric', })}. -&nbsp;
                        {`${item.end.getHours()}`.padStart(2, '0')}:{`${item.end.getMinutes()}`.padStart(2, '0')}&nbsp;
                        {item.end.toLocaleString('default', { month: 'short', weekday: 'long', day: 'numeric', })}.
                    </Typography>
                    {item.location &&
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{
                                mb: '10px',
                            }}
                        >
                            <LocationOn />
                            <Typography
                                m="5px 7px 0 7px"
                                color="#c5c5c5"
                                fontSize="10pt"
                                fontWeight="700"
                            >
                                {item.location}
                            </Typography>
                        </Stack>
                    }
                    <Typography
                        m="5px 7px 0 7px"
                        color="#c5c5c5"
                        fontSize="11pt"
                        fontWeight="400"
                        maxWidth="400px"
                        textOverflow="ellipsis"
                    >
                        {item.description}
                    </Typography>
                </Box>
            }
        </Popover>
    );
}