import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from "./App";
import Login from "./Login";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Mobile from "./App/Mobile";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <LocalizationProvider locale="en" dateAdapter={AdapterDayjs}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Routes path="/">
                    <Route index element={<App />} />
                    <Route path="login" element={<Login />} />
                    <Route path="mobile" element={<Mobile />} />
                    <Route path='*' element={<>404</>} />
                </Routes>
            </BrowserRouter>
        </LocalizationProvider>
    </React.StrictMode>
);
