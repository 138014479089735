import {Alert, Box, Button, CircularProgress, IconButton, Modal, Typography} from "@mui/material";
import {ImportExport, Warning} from "@mui/icons-material";
import {useState} from "react";
import useToken from "../../useToken";
import {TimeManager} from "../../timemanager";
import CourseImport from "./CourseImport";

export default function CanvasImport(props) {
    const {refreshTasks} = props;

    const [token] = useToken();

    const [menuOpen, setMenuOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [importResults, setImportResults] = useState(null);

    const beginImport = () => {
        setLoading(true);

        TimeManager.import.canvas(token)
            .then((results) => results.map((course) => {
                course.assignments.forEach((assignment) => {
                    assignment.name = `${course.code} | ${assignment.name}`;
                    assignment.start = assignment.created;
                    assignment.end = assignment.due;
                    assignment.included = false;

                    let end = assignment.end ? new Date(assignment.end) : null;
                    let start = assignment.start ? new Date(assignment.start) : null;

                    if (start && end) {
                        start.setTime(Math.max(end.getTime() - 1000*60*60*24*7, start.getTime()));
                    }
                    else if (end) {
                        start = new Date(end.getTime() - 1000*60*60*24*7);
                    }
                    else if (start) {
                        end = new Date(start.getTime() + 1000*60*60*24*7);
                    }

                    assignment.start = start;
                    assignment.end = end;
                });

                return course;
            }))
            .then(setImportResults)
            .catch((err) => setAlert(err.message))
            .finally(() => setLoading(false));
    };

    const onChange = (courseIndex) => {
        return (assignmentIndex, assignment) => {
            setImportResults((results) => {
                const newResults = [...results];
                newResults[courseIndex].assignments[assignmentIndex] = assignment;
                return results;
            });
        };
    };

    const createTasks = () => {
        setLoading(true);

        const assignments = [];

        importResults.forEach((course) => {
            course.assignments.forEach((assignment) => {
                if (assignment.included) {
                    assignments.push({
                        name: assignment.name,
                        start: assignment.start,
                        end: assignment.end,
                        link: `CANVAS:${course.id}:${assignment.id}`,
                        description: `URL: ${assignment.url}\nPoints: ${assignment.points}`,
                    });
                }
            });
        });

        TimeManager.time.task.batch(assignments, token)
            .then(() => setMenuOpen(false))
            .then(refreshTasks)
            .finally(() => setLoading(false));
    };

    return (
        <>
            <IconButton
                onClick={() => setMenuOpen(true)}
            >
                <ImportExport />
            </IconButton>

            <Modal
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {
                            xs: 'calc(100% - 40px)',
                            sm: '700px',
                        },
                        height: {
                            xs: 'calc(100% - 40px)',
                            sm: 'auto'
                        },
                        bgcolor: 'background.paper',
                        border: '1px solid #000',
                        boxShadow: 24,
                        borderRadius: '5px',
                        p: 4
                    }}
                >
                    <Typography
                        variant="h1"
                        component="h1"
                        mb="30px"
                        fontSize="18pt"
                        fontWeight="400"
                    >
                        Import Canvas assignments
                    </Typography>
                    {
                        loading ?
                            <>
                                <CircularProgress
                                    sx={{
                                        display: 'block',
                                        m: '0 auto'
                                    }}
                                />
                                <Typography
                                    m="15px 0"
                                    fontSize="12pt"
                                >
                                    Import will likely take several seconds to complete.
                                </Typography>
                            </>
                            :
                            importResults ?
                                <>
                                    <Box
                                        sx={{
                                            height: '600px',
                                            maxHeight: 'calc(100vh - 170px)',
                                            overflowY: 'scroll',
                                            mb: '30px',
                                        }}
                                    >
                                        {
                                            importResults.map((course, index) => (
                                                course.assignments.length > 0 ?
                                                <CourseImport
                                                    key={course.name}
                                                    course={course}
                                                    onChange={onChange(index)}
                                                /> :
                                                <div key={course.name}></div>
                                            ))
                                        }
                                    </Box>

                                    <Button
                                        variant="contained"
                                        onClick={createTasks}
                                        sx={{
                                            mr: '10px',
                                        }}
                                    >
                                        Create tasks
                                    </Button>

                                    <Button
                                        onClick={() => setMenuOpen(false)}
                                    >
                                        Cancel
                                    </Button>
                                </>
                                :
                                <>
                                    {alert &&
                                        <Alert
                                            icon={<Warning />}
                                            onClose={() => setAlert(null)}
                                            color="error"
                                            sx={{
                                                mb: '20px',
                                            }}
                                        >
                                            {alert}
                                        </Alert>
                                    }

                                    <Button
                                        variant="contained"
                                        onClick={beginImport}
                                        sx={{
                                            mr: '10px',
                                        }}
                                    >
                                        Start import
                                    </Button>

                                    <Button
                                        onClick={() => setMenuOpen(false)}
                                    >
                                        Cancel
                                    </Button>
                                </>
                    }
                </Box>
            </Modal>
        </>
    );
}