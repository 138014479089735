import {Box, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useEffect, useRef, useState} from "react";
import ItemPopover from "../ItemPopover";

const dayLabel = [
    'SUN',
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
];

export default function Weekday(props) {
    const {
        day,
        weekDate,
        //events,
        //tasks,
        periods,
        periodWindowSize,
        addScrollCallback,
        scrollCallback,
        beginEdit,
        submitDelete,
        markComplete,
        openCreateMenu,
        taskSlots,
        eventSlots,
        taskGridHeight,
        eventGridHeight,
        tasksCollapsed,
        eventsCollapsed,
        labels,
        independentLabel
    } = props;

    const date = new Date(weekDate.getTime());
    date.setDate(weekDate.getDate() + day);

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 1);

    const endDate = new Date(date.getTime());
    endDate.setHours(23, 59, 59);

    const beginDate = new Date(date.getTime());
    beginDate.setHours(0, 0, 1);

    //const activeEvents = events.filter(e => e.start <= endDate && beginDate <= e.end);
    //const activeTasks = tasks.filter(t => t.start <= endDate && beginDate <= t.end);
    const activePeriods = periods.filter(p => p.start <= endDate && beginDate <= p.end);

    //let eventsAdded = 0;
    //let tasksAdded = 0;

    const periodWindowRef = useRef();

    const [expandedMenuAnchor, setExpandedMenuAnchor] = useState(null);
    const [expandedItem, setExpandedItem] = useState(null);
    const [expandedType, setExpandedType] = useState(null);

    const onScrollWindow = () => {
        scrollCallback(periodWindowRef.current.scrollTop);
    };

    useEffect(() => {
        addScrollCallback((scroll) => {
            periodWindowRef.current.scrollTop = scroll;
        });
    }, [addScrollCallback, periodWindowRef]);

    const expand = (type, item) => {
        return (e) => {
            setExpandedItem(item);
            setExpandedType(type);
            setExpandedMenuAnchor(e.currentTarget);
        }
    };

    return (
        <Grid2
            sm={1}
            sx={{
                background: '#353535',
                height: '100%',
                borderRight: 'solid 1px #444',
            }}
        >
            <Box
                sx={{
                    maxHeight: '100%',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '30px',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: currentDate.getTime() === date.getTime() ? '#1b9ed1' : '#1c1e1e',
                        borderBottom: 'solid 1px #444',
                        userSelect: 'none',
                    }}
                >
                    <Typography
                        color="white"
                        fontFamily="'Inter', sans-serif"
                        fontWeight="700"
                    >
                        {dayLabel[day]}. &nbsp; {date.getMonth() + 1}/{date.getDate()}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                        height: `${eventGridHeight * (eventsCollapsed ? 8 : 33) + 9}px`,
                        borderTop: 'solid 1px #00000000',
                        borderBottom: 'solid 1px #444',
                        minHeight: '90px',
                    }}
                >
                    {eventSlots.map((slot, index) => {
                        if (slot) {
                            const labelKey = slot.event.labels?.[0] ? parseInt(slot.event.labels?.[0]) : 0;
                            const label = labels.find((label) => label.key === labelKey);

                            return (
                                <Box
                                    key={slot.event.name + slot.event.key}
                                    sx={{
                                        position: 'absolute',
                                        left: slot.start ? '3px' : '0',
                                        right: slot.end ? '3px' : '-1px',
                                        top: `${3 + Math.max(index * (eventsCollapsed ? 8 : 33), 0)}px`,
                                        height: eventsCollapsed ? '5px' : '30px',
                                        border: 'hidden',
                                        borderTopLeftRadius: slot.start ? '15px' : '0',
                                        borderBottomLeftRadius: slot.start ? '15px' : '0',
                                        borderTopRightRadius: slot.end ? '15px' : '0',
                                        borderBottomRightRadius: slot.end ? '15px' : '0',
                                        background: label?.color || '#474747',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        userSelect: 'none',
                                    }}
                                    onClick={expand('Event', slot.event)}
                                >
                                    {(date.getDate() === weekDate.getDate() || slot.start || independentLabel) && !eventsCollapsed &&
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                bottom: 0,
                                                left: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                maxWidth: `calc(${(slot.remaining + 1) * 100}% - 20px)`,
                                            }}
                                        >
                                            <Typography
                                                ml="12px"
                                                color="white"
                                                fontSize="10pt"
                                                fontWeight="700"
                                                fontFamily="'Roboto', sans-serif"
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    zIndex: '200',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                {slot.event.name}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                            );
                        } else {
                            return undefined;
                        }
                    })}
                    {/*{activeEvents.map((e) => {
                        const start = e.start.getDate() === date.getDate();
                        const end = e.end.getDate() === date.getDate();

                        let verticalOffset = -eventsAdded;

                        for (let i = 0; events[i] !== e; ++i) {

                            const iEvent = events[i];

                            const eventStart = new Date(iEvent.start);
                            eventStart.setHours(0, 0, 1);

                            const eventEnd = new Date(iEvent.end);
                            eventEnd.setHours(23, 59, 59);

                            if (eventStart <= e.end && eventEnd >= e.start) {
                                verticalOffset++;
                            }
                        }

                        eventsAdded += verticalOffset + 1;

                        return (
                            <Box
                                key={e.name}
                                sx={{
                                    width: `calc(100% + (${(start ? -3 : 0.5) + end ? -3 : 0.5 }px))`,
                                    height: '30px',
                                    border: 'hidden',
                                    borderTopLeftRadius: start ? '5px' : '0',
                                    borderBottomLeftRadius: start ? '5px' : '0',
                                    borderTopRightRadius: end ? '5px' : '0',
                                    borderBottomRightRadius: end ? '5px' : '0',
                                    marginTop: `${3 + verticalOffset * 33}px`,
                                    marginLeft: start ? '3px' : '0',
                                    marginRight: end ? '3px' : '0',
                                    background: '#193255',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={expand('Event', e)}
                            >
                                {(date.getDate() === weekDate.getDate() || start) &&
                                    <Typography
                                        ml="7px"
                                        color="white"
                                        fontSize="10pt"
                                        fontWeight="700"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            zIndex: '200'
                                        }}
                                    >
                                        {e.name}
                                    </Typography>
                                }
                            </Box>
                        );
                    })}*/}
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                        height: `${(taskGridHeight) * (tasksCollapsed ? 8 : 33) + 9}px`,
                        borderTop: 'solid 1px #00000000',
                        borderBottom: 'solid 1px #444',
                        minHeight: '90px',
                        userSelect: 'none',
                    }}
                >
                    {taskSlots.map((slot, index) => {
                        if (slot) {
                            const labelKey = slot.task.labels?.[0] ? parseInt(slot.task.labels?.[0]) : 0;
                            const label = labels.find((label) => label.key === labelKey);

                            return (
                                <Box
                                    key={slot.task.name + slot.task.key}
                                    sx={{
                                        position: 'absolute',
                                        left: slot.start ? '3px' : '0',
                                        right: slot.end ? '3px' : '-1px',
                                        top: `${3 + Math.max(index * (tasksCollapsed ? 8 : 33), 0)}px`,
                                        height: tasksCollapsed ? '5px' : '30px',
                                        border: 'hidden',
                                        borderTopLeftRadius: slot.start ? '15px' : '0',
                                        borderBottomLeftRadius: slot.start ? '15px' : '0',
                                        borderTopRightRadius: slot.end ? '15px' : '0',
                                        borderBottomRightRadius: slot.end ? '15px' : '0',
                                        background: label?.color || '#474747', //slot.task.complete ? '#503524' : '#714023',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        filter: slot.task.complete ? 'brightness(0.8)' : 'none',
                                    }}
                                    onClick={expand('Task', slot.task)}
                                >
                                    {(date.getDate() === weekDate.getDate() || slot.start || independentLabel) && !tasksCollapsed &&
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                bottom: 0,
                                                left: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                maxWidth: `calc(${(slot.remaining + 1) * 100}% - 20px)`,
                                            }}
                                        >
                                            <Typography
                                                ml="12px"
                                                color={slot.task.complete ? '#b5b5b5' : '#ffffff'}
                                                fontSize="10pt"
                                                fontWeight="700"
                                                fontFamily="'Roboto', sans-serif"
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    zIndex: '200',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                {slot.task.name}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                            );
                        } else {
                            return undefined;
                        }
                    })}
                    {/*{activeTasks.map((t) => {
                        const start = t.start.getDate() === date.getDate();
                        const end = t.end.getDate() === date.getDate();

                        let verticalOffset = -tasksAdded;

                        for (let i = 0; tasks[i] !== t; ++i) {
                            const iTask = tasks[i];

                            const taskStart = new Date(iTask.start);
                            taskStart.setHours(0, 0, 1);

                            const taskEnd = new Date(iTask.end);
                            taskEnd.setHours(23, 59, 59);

                            if (taskStart <= t.end && taskEnd >= t.start) {
                                verticalOffset++;
                            }
                        }

                        tasksAdded += verticalOffset + 1;

                        return (
                            <Box
                                key={t.name}
                                sx={{
                                    width: start || end ? 'calc(100% - 2px)' : 'calc(100% + 1px)',
                                    height: '30px',
                                    border: 'hidden',
                                    borderTopLeftRadius: start ? '5px' : '0',
                                    borderBottomLeftRadius: start ? '5px' : '0',
                                    borderTopRightRadius: end ? '5px' : '0',
                                    borderBottomRightRadius: end ? '5px' : '0',
                                    marginTop: `${3 + Math.max(verticalOffset * 33, 0)}px`,
                                    marginLeft: start ? '3px' : '0',
                                    marginRight: end ? '3px' : '0',
                                    background: t.complete ? '#503524' : '#714023',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={expand('Task', t)}
                            >
                                {(date.getDate() === weekDate.getDate() || start) &&
                                    <Typography
                                        ml="7px"
                                        color={t.complete ? '#b5b5b5' : '#ffffff'}
                                        fontSize="10pt"
                                        fontWeight="700"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            zIndex: '200'
                                        }}
                                    >
                                        {t.name}
                                    </Typography>
                                }
                            </Box>
                        );
                    })}*/}
                </Box>
                <Box
                    ref={periodWindowRef}
                    sx={{
                        overflowY: 'scroll',
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                        height: `${periodWindowSize}px`,
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        cursor: 'crosshair',
                    }}
                    onScroll={onScrollWindow}
                    onClick={(e) => {
                        // Round minutes to nearest 5.
                        const totalMinutes = Math.round((e.clientY - e.currentTarget.offsetTop + e.currentTarget.scrollTop) / 5) * 5;
                        const hours = Math.floor(totalMinutes / 60);
                        const minutes = totalMinutes % 60;

                        const startDate = new Date(beginDate.getTime());
                        startDate.setHours(hours, minutes);

                        // Set end date one hour from start.
                        const endDate = new Date(startDate.getTime());
                        endDate.setHours(Math.min(hours + 1, 23), minutes);

                        openCreateMenu('Period', { start: startDate, end: endDate })();
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            height: 'calc(24 * 60px + 20px)',
                        }}
                    >
                        {activePeriods.map((p) => {
                            const labelKey = p.labels?.[0] ? parseInt(p.labels?.[0]) : 0;
                            const label = labels.find((label) => label.key === labelKey);

                            const duration = (p.end - p.start) / 1000 / 60;

                            return (
                                <Box
                                    key={p.name + p.key}
                                    sx={{
                                        position: 'absolute',
                                        top: `${(p.start.getHours() * 60 + p.start.getMinutes()) + 1}px`,
                                        left: '4px',
                                        right: '4px',
                                        height: `${duration - 2}px`,
                                        minHeight: `${duration - 2}px`,
                                        background: label?.color || '#474747', //'#1a5038',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        overflow: 'hidden',
                                        userSelect: 'none',
                                        '&:hover': duration < 50 && {
                                            height: 'auto',
                                            pb: '5px',
                                        }
                                    }}
                                    onClick={(e) => {
                                        expand('Period', p)(e)
                                        e.stopPropagation();
                                    }}
                                >
                                    <Typography
                                        m="5px 7px 0 7px"
                                        color="white"
                                        fontSize="10pt"
                                        fontWeight="700"
                                        fontFamily="'Inter', sans-serif"
                                        height="20px"
                                        p="0"
                                        sx={{
                                            textWrap: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {p.name}
                                    </Typography>
                                    <Typography
                                        m="0 7px 0 7px"
                                        color="white"
                                        fontSize="10pt"
                                        fontWeight="400"
                                        p="0"
                                    >
                                        {`${p.start.getHours()}`.padStart(2, '0')}:{`${p.start.getMinutes()}`.padStart(2, '0')} -&nbsp;
                                        {`${p.end.getHours()}`.padStart(2, '0')}:{`${p.end.getMinutes()}`.padStart(2, '0')}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </div>
                </Box>
            </Box>

            <ItemPopover
                anchor={expandedMenuAnchor}
                onClose={() => setExpandedMenuAnchor(null)}
                type={expandedType}
                item={expandedItem}
                beginDate={beginDate}
                endDate={endDate}
                beginEdit={beginEdit}
                openCreateMenu={openCreateMenu}
                markComplete={markComplete}
                submitDelete={submitDelete}
            />
        </Grid2>
    );
}