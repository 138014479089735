import Grid2 from "@mui/material/Unstable_Grid2";
import {
    Box, Button,
    ButtonGroup, Checkbox, Chip, FormControl, FormControlLabel,
    IconButton, InputLabel,
    Menu,
    MenuItem,
    Modal, OutlinedInput, Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import Weekday from "./Weekday";
import {Add, ArrowLeft, ArrowRight, Circle} from "@mui/icons-material";
import useLoginRequired from "../useLoginRequired";
import {TimeManager} from "../timemanager";
import useToken from "../useToken";
import {useEffect, useRef, useState} from "react";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CanvasImport from "./CanvasImport";
import Sidebar from "./Sidebar";
import useResource from "../useResource";
import LabelsMenu from "./LabelsMenu";

export default function App() {
    document.title = 'Time Manager';

    console.log('Main Render');

    useLoginRequired();

    const [token] = useToken();

    const currentDate = new Date();
    const currentWeekDate = new Date();
    currentWeekDate.setTime(currentWeekDate.getTime() - (currentDate.getDay() * 24 * 60 * 60 * 1000));
    currentWeekDate.setHours(0, 0, 0, 1);

    const [weekDate, setWeekDate] = useState(currentWeekDate);

    const hourDisplayRef = useRef();
    const [periodWindowSize, setPeriodWindowSize] = useState(0);
    const [scrollCallbacks, setScrollCallbacks] = useState([]);

    const [createDropdownAnchor, setCreateDropdownAnchor] = useState(null);
    const [createMenuOpen, setCreateMenuOpen] = useState(false);
    const [createEditKey, setCreateEditKey] = useState(null);
    const [createType, setCreateType] = useState('event');

    const [periods, setPeriods] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [events, setEvents] = useState([]);

    const [taskGrid, setTaskGrid] = useState([[], [], [], [], [], [], []]);
    const [taskGridHeight, setTaskGridHeight] = useState(0);
    const [eventGrid, setEventGrid] = useState([[], [], [], [], [], [], []]);
    const [eventGridHeight, setEventGridHeight] = useState(0);

    const [tasksCollapsed, setTasksCollapsed] = useState(true);
    const [eventsCollapsed, setEventsCollapsed] = useState(true);

    const [createName, setCreateName] = useState(null);
    const [createStart, setCreateStart] = useState(null);
    const [createEnd, setCreateEnd] = useState(null);
    const [createDescription, setCreateDescription] = useState(null);
    const [createLocation, setCreateLocation] = useState(null);
    const [createLabels, setCreateLabels] = useState([]);
    const [createRecurringType, setCreateRecurringType] = useState(null);
    const [createRecurringParameter, setCreateRecurringParameter] = useState('');
    const [createRecurringEnd, setCreateRecurringEnd] = useState(null);

    const [labels, refreshLabels] = useResource(TimeManager.time.label.list.bind(null, token), []);

    const refreshPeriods = () => {
        TimeManager.time.period.getBetween(weekDate, new Date(weekDate.getTime() + 1000*60*60*24*7), token)
            .then(setPeriods);
    };

    const refreshEvents = () => {
        TimeManager.time.event.getBetween(weekDate, new Date(weekDate.getTime() + 1000*60*60*24*7), token)
            .then(setEvents);
    };

    const refreshTasks = () => {
        TimeManager.time.task.getBetween(weekDate, new Date(weekDate.getTime() + 1000*60*60*24*7), token)
            .then(setTasks);
    };

    const getDays = (date) => Math.floor((date.getTime() - date.getTimezoneOffset() * 60 * 1000) / 1000 / 60 / 60 / 24);

    const generateTaskGrid = () => {
        const taskGrid = [[], [], [], [], [], [], []];

        const weekStartDay = getDays(weekDate);

        tasks.forEach((task) => {
            const {start, end} = task;

            const startDay = getDays(start);
            const endDay = getDays(end);

            const weekRelativeBegin = Math.max(startDay, weekStartDay);
            const weekRelativeEnd = Math.min(endDay, weekStartDay + 6);

            const beginTaskGridSlot = taskGrid[weekRelativeBegin - weekStartDay];

            if (beginTaskGridSlot === undefined) {
                return;
            }

            const undefinedIndex = beginTaskGridSlot.findIndex((slot) => slot === undefined);
            const verticalOffset = undefinedIndex === -1 ? beginTaskGridSlot.length : undefinedIndex;

            for (let i = weekRelativeBegin; i <= weekRelativeEnd; ++i) {
                taskGrid[i - weekStartDay][verticalOffset] = {
                    start: i === startDay,
                    end: i === endDay,
                    remaining: endDay - i,
                    task
                };
            }
        });

        let height = 0;
        taskGrid.forEach((slot) => height = Math.max(height, slot.length));

        setTaskGridHeight(height);
        setTaskGrid(taskGrid);
    };

    const generateEventGrid = () => {
        const eventGrid = [[], [], [], [], [], [], []];

        const weekStartDay = getDays(weekDate);

        events.forEach((event) => {
            const {start, end} = event;

            if (event.recurring_time) {
                return;
            }

            const startDay = getDays(start);
            const endDay = getDays(end);

            const weekRelativeBegin = Math.max(startDay, weekStartDay);
            const weekRelativeEnd = Math.min(endDay, weekStartDay + 6);

            const beginEventGridSlot = eventGrid[weekRelativeBegin - weekStartDay];

            if (beginEventGridSlot === undefined) {
                return;
            }

            const undefinedIndex = beginEventGridSlot.findIndex((slot) => slot === undefined);
            const verticalOffset = undefinedIndex === -1 ? beginEventGridSlot.length : undefinedIndex;

            for (let i = weekRelativeBegin; i <= weekRelativeEnd; ++i) {
                eventGrid[i - weekStartDay][verticalOffset] = {
                    start: i === startDay,
                    end: i === endDay,
                    remaining: endDay - i,
                    event
                };
            }
        });

        events.filter((event) => event.recurring_time).forEach((event) => {
            const recurringTime = event.recurring_time;

            if (recurringTime.startsWith('W:')) {
                for (let i = 0; i < 7; ++i) {
                    if (recurringTime.includes(`${i + 1}`)) {
                        const undefinedIndex = eventGrid[i].findIndex((slot) => slot === undefined);
                        const verticalOffset = undefinedIndex === -1 ? eventGrid[i].length : undefinedIndex;

                        const start = new Date(event.start.getTime());
                        start.setFullYear(weekDate.getFullYear(), weekDate.getMonth(), weekDate.getDate() + i);

                        if (start < event.start) {
                            continue;
                        }

                        const end = new Date(event.end.getTime());
                        end.setFullYear(weekDate.getFullYear(), weekDate.getMonth(), weekDate.getDate() + i);

                        eventGrid[i][verticalOffset] = {
                            start: true,
                            end: true,
                            remaining: 0,
                            event: {
                                ...event,
                                start,
                                end
                            },
                            recurringSource: event,
                        };
                    }
                }
            }
        });

        let height = 0;
        eventGrid.forEach((slot) => height = Math.max(height, slot.length));

        setEventGridHeight(height);
        setEventGrid(eventGrid);
    };

    const scroll = (value) => scrollCallbacks.forEach((callback) => callback(value));
    const onScroll = (e) => scroll(e.currentTarget.scrollTop);

    const scrollCallback = (value) => {
        hourDisplayRef.current.scrollTop = value;
        scroll(value);
    };

    const refreshPeriodWindowSize = () => {
        setPeriodWindowSize(hourDisplayRef.current.offsetHeight);
    };

    useEffect(refreshPeriodWindowSize, [
        hourDisplayRef,
        weekDate,
        taskGrid,
        eventGrid,
        periods,
        tasksCollapsed,
        eventsCollapsed
    ]);

    useEffect(refreshPeriods, [weekDate, setPeriods, token]);
    useEffect(refreshEvents, [weekDate, setEvents, token]);
    useEffect(refreshTasks, [weekDate, setTasks, token]);

    useEffect(generateTaskGrid, [tasks, setTaskGrid, weekDate, setTaskGridHeight]);
    useEffect(generateEventGrid, [events, setEventGrid, weekDate, setTaskGridHeight]);

    const openCreateMenu = (type, defaults = {}) => {
        return () => {
            setCreateDropdownAnchor(null);

            setCreateType(type);
            setCreateEditKey(null);

            setCreateName(defaults.name || `New ${type}`);
            setCreateStart(dayjs(defaults.start || new Date()));

            const endDate = new Date();
            endDate.setHours(23);
            endDate.setMinutes(59);
            setCreateEnd(dayjs(defaults.end || endDate));

            setCreateDescription(defaults.description || '');
            setCreateLocation(defaults.location || '');
            setCreateLabels(defaults.labels ? defaults.labels.map((label) => parseInt(label)) : []);

            setCreateRecurringType(null);
            setCreateRecurringParameter('');
            setCreateRecurringEnd(null);

            setCreateMenuOpen(true);
        };
    };

    const beginEdit = (type, key) => {
        return () => {
            setCreateType(type);
            setCreateEditKey(key);

            let current = undefined;

            switch (type) {
                case 'Event':
                    current = events.find(e => e.key === key);
                    break;
                case 'Task':
                    current = tasks.find(t => t.key === key);
                    break;
                case 'Period':
                    current = periods.find(p => p.key === key);
                    break;
                default:
                    break;
            }

            if (current === undefined) {
                return;
            }

            setCreateName(current.name);
            setCreateStart(dayjs(current.start));
            setCreateEnd(dayjs(current.end));

            setCreateDescription(current.description || '');
            setCreateLocation(current.location || '');
            setCreateLabels(current.labels.map((label) => parseInt(label)));

            if (type === 'Event' && current.recurring_time) {
                const [recurringType, recurringParameter] = current.recurring_time.split(':');

                setCreateRecurringType(recurringType);
                setCreateRecurringParameter(recurringParameter);

                setCreateRecurringEnd(current.recurring_end ? dayjs(current.recurring_end) : null);
            }

            setCreateMenuOpen(true);
        };
    };

    const submitCreate = () => {
        switch (createType) {
            case 'Event':
                TimeManager.time.event.alter({
                    key: createEditKey || undefined,
                    name: createName,
                    start: createStart.toDate(),
                    end: createEnd.toDate(),
                    description: createDescription.length > 0 ? createDescription : undefined,
                    location: createLocation.length > 0 ? createLocation : undefined,
                    labels: createLabels || [],
                    recurring_time: createRecurringType !== null ? `${createRecurringType}:${createRecurringParameter}` : undefined,
                    recurring_end: createRecurringEnd ? createRecurringEnd.toDate() : undefined,
                }, token).then(() => refreshEvents());
                break;
            case 'Task':
                TimeManager.time.task.alter({
                    key: createEditKey || undefined,
                    name: createName,
                    start: createStart.toDate(),
                    end: createEnd.toDate(),
                    description: createDescription.length > 0 ? createDescription : undefined,
                    labels: createLabels || [],
                }, token).then(() => refreshTasks());
                break;
            case 'Period':
                TimeManager.time.period.alter({
                    key: createEditKey || undefined,
                    name: createName,
                    start: createStart.toDate(),
                    end: createEnd.toDate(),
                    description: createDescription.length > 0 ? createDescription : undefined,
                    location: createLocation.length > 0 ? createLocation : undefined,
                    labels: createLabels || [],
                }, token).then(() => refreshPeriods());
                break;
            default:
                break;
        }

        setCreateMenuOpen(false);
    };

    const markComplete = (key, complete) => {
        TimeManager.time.task.alter({
            key,
            complete
        }, token).then(refreshTasks);
    };

    const submitDelete = (type, key) => {
        return () => {
            type = type || createType;
            key = key || createEditKey;

            switch (type) {
                case 'Event':
                    TimeManager.time.event.alter({
                        key: key,
                        delete: true,
                    }, token).then(() => refreshEvents());
                    break;
                case 'Task':
                    TimeManager.time.task.alter({
                        key: key,
                        delete: true,
                    }, token).then(() => refreshTasks());
                    break;
                case 'Period':
                    TimeManager.time.period.alter({
                        key: key,
                        delete: true,
                    }, token).then(() => refreshPeriods());
                    break;
                default:
                    break;
            }

            setCreateMenuOpen(false);
        };
    };

    return (
        <>
            <Stack
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                }}
                direction="row"
            >
                <Box
                    sx={{
                        background: '#222',
                        borderRight: 'solid 1px #444',
                        width: '350px',
                    }}
                >
                    <Sidebar
                        periods={periods}
                        tasks={tasks}
                        events={events}
                        labels={labels}
                    />
                </Box>
                <Box
                    sx={{
                        background: '#151515',
                        flexGrow: 100
                    }}
                >
                    <Stack direction="row">
                        <Box
                            sm={1}
                            sx={{
                                background: '#151515',
                                borderRight: 'solid 1px #444',
                                marginTop: '92px',
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'calc(100vh - 92px)',
                                width: '30px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minHeight: `${Math.max(eventGridHeight * (eventsCollapsed ? 8 : 33) + 9, 90)}px`,
                                    maxHeight: '100%',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setEventsCollapsed((collapsed) => !collapsed)}
                            >
                                <Typography
                                    color="#ccc"
                                    textTransform="uppercase"
                                    ml="3px"
                                    fontWeight="700"
                                    sx={{
                                        writingMode: 'vertical-rl',
                                        textOrientation: 'mixed',
                                        transform: 'rotate(180deg)',
                                        userSelect: 'none',
                                        fontFamily: '"Inter", sans-serif',
                                    }}
                                >
                                    Events
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minHeight: `${Math.max(taskGridHeight * (tasksCollapsed ? 8 : 33) + 9, 90)}px`,
                                    cursor: 'pointer',
                                }}
                                onClick={() => setTasksCollapsed((collapsed) => !collapsed)}
                            >
                                <Typography
                                    color="#ccc"
                                    textTransform="uppercase"
                                    ml="3px"
                                    fontWeight="700"
                                    sx={{
                                        writingMode: 'vertical-rl',
                                        textOrientation: 'mixed',
                                        transform: 'rotate(180deg)',
                                        userSelect: 'none',
                                        fontFamily: '"Inter", sans-serif',
                                    }}
                                >
                                    Tasks
                                </Typography>
                            </Box>
                            <Box
                                ref={hourDisplayRef}
                                sx={{
                                    overflowY: 'scroll',
                                    '::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                    msOverflowStyle: 'none',
                                    scrollbarWidth: 'none',
                                    flexGrow: '100',
                                    userSelect: 'none',
                                }}
                                onResize={(e) => setPeriodWindowSize(e.currentTarget.offsetHeight)}
                                onScroll={onScroll}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        height: 'calc(24 * 60px) + 20px'
                                    }}
                                >
                                    {[...new Array(24).keys()].map((hour) => (
                                        <Typography
                                            key={hour}
                                            width="100%"
                                            height="60px"
                                            color="white"
                                            sx={{
                                                position: 'absolute',
                                                zIndex: '20',
                                                top: `${hour * 60}px`,
                                                left: '0',
                                                pt: '3px',
                                                textAlign: 'center',
                                                fontFamily: 'monospace',
                                            }}
                                        >
                                            {`${hour}`.padStart(2, '0')}
                                        </Typography>
                                    ))}
                                </div>
                            </Box>
                        </Box>
                        <div style={{ flexGrow: 100, height: '100%' }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '60px',
                                    background: '#25292e',
                                    borderBottom: 'solid 1px #444',
                                }}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    sx={{
                                        ml: '10px',
                                        mr: '10px',
                                        pt: '10px',
                                    }}
                                >
                                    <Box>
                                        <IconButton
                                            onClick={() => {
                                                setWeekDate((oldWeekDate) => new Date(oldWeekDate.getTime() - 1000*60*60*24*7));
                                            }}
                                            sx={{
                                                color: 'white',
                                            }}
                                        >
                                            <ArrowLeft />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                setWeekDate((oldWeekDate) => new Date(oldWeekDate.getTime() + 1000*60*60*24*7));
                                            }}
                                            sx={{
                                                color: 'white',
                                            }}
                                        >
                                            <ArrowRight />
                                        </IconButton>
                                    </Box>
                                    <Box>

                                    </Box>
                                    <Box
                                        sx={{
                                            'svg': {
                                                color: 'white',
                                            },
                                        }}
                                    >
                                        <CanvasImport refreshTasks={refreshTasks} />

                                        <LabelsMenu labels={labels} refreshLabels={refreshLabels} />

                                        <IconButton
                                            onClick={(e) => {
                                                setCreateDropdownAnchor(e.target);
                                            }}
                                        >
                                            <Add />
                                        </IconButton>
                                        <Menu
                                            anchorEl={createDropdownAnchor}
                                            open={Boolean(createDropdownAnchor)}
                                            onClose={() => setCreateDropdownAnchor(null)}
                                        >
                                            <MenuItem
                                                onClick={openCreateMenu('Event')}
                                            >
                                                New event
                                            </MenuItem>
                                            <MenuItem
                                                onClick={openCreateMenu('Task')}
                                            >
                                                New task
                                            </MenuItem>
                                            <MenuItem
                                                onClick={openCreateMenu('Period')}
                                            >
                                                New period
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </Stack>
                            </Box>
                            <Grid2
                                container
                                columns={7}
                                sx={{
                                    height: '100%',
                                    alignItems: 'stretch',
                                }}
                            >
                                {[...new Array(7).keys()].map((index) => (
                                    <Weekday
                                        key={index}
                                        periods={periods}
                                        weekDate={weekDate}
                                        day={index}
                                        periodWindowSize={periodWindowSize}
                                        addScrollCallback={(callback) => scrollCallbacks[index] || setScrollCallbacks((callbacks) => index === 0 ? [callback] : [...callbacks, callback]) }
                                        scrollCallback={scrollCallback}
                                        beginEdit={beginEdit}
                                        submitDelete={submitDelete}
                                        markComplete={markComplete}
                                        openCreateMenu={openCreateMenu}
                                        taskSlots={taskGrid[index]}
                                        eventSlots={eventGrid[index]}
                                        taskGridHeight={taskGridHeight}
                                        eventGridHeight={eventGridHeight}
                                        tasksCollapsed={tasksCollapsed}
                                        eventsCollapsed={eventsCollapsed}
                                        labels={labels}
                                    />
                                ))}
                            </Grid2>
                        </div>
                    </Stack>
                </Box>
            </Stack>
            <Modal
                open={createMenuOpen}
                onClose={() => setCreateMenuOpen(false)}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '500px',
                        bgcolor: 'background.paper',
                        border: '1px solid #000',
                        boxShadow: 24,
                        borderRadius: '5px',
                        p: 4,
                    }}
                >
                    <Typography variant="h6" component="h2" mb="20px">
                        {createEditKey !== null ? 'Edit' : 'Create'} {createType}
                    </Typography>
                    {createEditKey === null &&
                        <ButtonGroup
                            size="small"
                            sx={{
                                mb: '30px',
                            }}
                        >
                            {['Event', 'Task', 'Period'].map((type) => (
                                <Button
                                    key={type}
                                    onClick={() => setCreateType(type)}
                                    variant={createType === type ? 'contained' : 'outlined'}
                                >
                                    {type}
                                </Button>
                            ))}
                        </ButtonGroup>
                    }

                    <TextField
                        label="Name"
                        value={createName}
                        onChange={(e) => setCreateName(e.target.value)}
                        sx={{
                            mb: '20px',
                        }}
                        fullWidth
                    />

                    <Stack
                        direction="row"
                        sx={{
                            mb: '20px',
                        }}
                    >
                        <DateTimePicker
                            label="Start"
                            value={createStart}
                            onChange={(value) => setCreateStart(value)}
                        />

                        <ButtonGroup
                            sx={{
                                ml: '10px',
                            }}
                        >
                            <Button
                                onClick={() => setCreateStart((oldStart) => {
                                    const newStart = oldStart.toDate();
                                    newStart.setHours(0, 0, 0, 1);
                                    return dayjs(newStart);
                                })}
                            >
                                Beginning
                            </Button>
                            <Button
                                onClick={() => setCreateStart((oldStart) => {
                                    const newStart = oldStart.toDate();
                                    newStart.setHours(12, 0, 0, 0);
                                    return dayjs(newStart);
                                })}
                            >
                                Noon
                            </Button>
                        </ButtonGroup>
                    </Stack>

                    <Stack
                        direction="row"
                        sx={{
                            mb: '20px',
                        }}
                    >
                        <DateTimePicker
                            label="End"
                            value={createEnd}
                            onChange={(value) => setCreateEnd(value)}
                        />

                        <ButtonGroup
                            sx={{
                                ml: '10px',
                            }}
                        >
                            <Button
                                onClick={() => setCreateEnd((oldEnd) => {
                                    const newEnd = oldEnd.toDate();
                                    newEnd.setHours(23, 59, 59, 999);
                                    return dayjs(newEnd);
                                })}
                            >
                                Midnight
                            </Button>
                        </ButtonGroup>
                    </Stack>

                    {createType === 'Event' &&
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => {
                                        setCreateRecurringType(createRecurringType === null ? 'W' : null);
                                        setCreateRecurringParameter('');
                                    }}
                                    checked={createRecurringType !== null}
                                />
                            }
                            label="Recurring event"
                            sx={{
                                mb: createRecurringType === 'W' ? '10px' : '20px',
                            }}
                        />
                    }

                    {createType === 'Event' && createRecurringType === 'W' &&
                        <>
                            <ButtonGroup
                                sx={{
                                    display: 'block',
                                    mb: '20px'
                                }}
                            >
                                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => {
                                    const dayNumber = index + 1;
                                    const included = createRecurringParameter.includes(dayNumber.toString());

                                    const click = () => {
                                        if (included) {
                                            setCreateRecurringParameter((currentParameter) => currentParameter.replaceAll(dayNumber.toString(), ''));
                                        } else {
                                            setCreateRecurringParameter((currentParameter) => currentParameter + dayNumber);
                                        }
                                    };

                                    return (
                                        <Button
                                            key={day + index}
                                            variant={included ? "contained" : "outlined"}
                                            sx={{
                                                display: 'inline-block',
                                            }}
                                            onClick={click}
                                        >
                                            {day}
                                        </Button>
                                    );
                                })}
                            </ButtonGroup>
                            <Stack
                                direction="row"
                                sx={{
                                    mb: '30px',
                                }}
                            >
                                <DateTimePicker
                                    label="Recurring End Date"
                                    value={createRecurringEnd}
                                    onChange={(value) => setCreateRecurringEnd(value)}
                                />

                                <ButtonGroup
                                    sx={{
                                        ml: '10px',
                                    }}
                                >
                                    <Button
                                        onClick={() => setCreateRecurringEnd(null)}
                                    >
                                        None
                                    </Button>
                                </ButtonGroup>
                            </Stack>
                        </>
                    }

                    <FormControl
                        sx={{
                            mb: '20px',
                        }}
                        fullWidth
                    >
                        <InputLabel>Labels</InputLabel>
                        <Select
                            variant="outlined"
                            input={<OutlinedInput label="Labels" />}
                            value={createLabels}
                            onChange={(e) => setCreateLabels(e.target.value)}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((labelKey) => {
                                        const label = labels.find((label) => label.key === labelKey);

                                        return label ?
                                            <Chip key={labelKey} label={label.name} sx={{ color: 'white', backgroundColor: label.color || '#454545' }} /> :
                                            undefined;
                                    })}
                                </Box>
                            )}
                            multiple
                        >
                            {labels.map((label) => (
                                <MenuItem
                                    key={label.key}
                                    value={label.key}
                                >
                                    <Circle
                                        sx={{
                                            color: label.color,
                                            mr: '10px',
                                        }}
                                    /> {label.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {createType !== 'Task' &&
                        <TextField
                            label="Location (optional)"
                            value={createLocation}
                            onChange={(e) => setCreateLocation(e.target.value)}
                            sx={{
                                mb: '20px',
                            }}
                            fullWidth
                        />
                    }

                    <TextField
                        label="Description (optional)"
                        value={createDescription}
                        onChange={(e) => setCreateDescription(e.target.value)}
                        minRows={3}
                        sx={{
                            mb: '20px',
                        }}
                        multiline
                        fullWidth
                    />

                    <Button
                        variant="contained"
                        onClick={submitCreate}
                        sx={{
                            float: 'right',
                        }}
                    >
                        Done
                    </Button>

                    {createEditKey !== null && (
                        <Button
                            variant="contained"
                            onClick={submitDelete()}
                            sx={{
                                float: 'right',
                                mr: '10px',
                            }}
                            color="error"
                        >
                            Delete
                        </Button>
                    )}

                    <Button
                        onClick={() => setCreateMenuOpen(false)}
                        sx={{
                            float: 'right',
                            mr: '10px',
                        }}
                        color="primary"
                    >
                        Cancel
                    </Button>
                </Box>
            </Modal>
        </>
    );
}